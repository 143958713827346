import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { sendScoutRequest } from '../../../redux/actions/Transfers';

import {
  getSearchPlayerData,
  getPlayerDataById,
} from '../../../redux/selector/Transfers';
import { getPopupsList } from '../../../redux/selector/Popups';

const ScoutPopup = ({ cash, popupName, id }) => {
  const [defaultStep, showDefaultStep] = useState(true);

  const { isPopupLoading, league } = useSelector(getSearchPlayerData);
  const data = useSelector(getPlayerDataById(id));
  const isInLeague = league === data.league?._id;
  const penaltiSize = 3000;
  const freeAgentPopupData = useSelector(getPopupsList);
  const { error } = freeAgentPopupData[popupName];
  const NSF = cash - penaltiSize < 0;
  useEffect(() => {
    showDefaultStep(true);
  }, []);
  const dispatch = useDispatch();

  const sendScout = () => {
    showDefaultStep(false);
    if (!NSF) {
      dispatch(sendScoutRequest(id));
    }
  };

  const hidePopup = () => {
    if (!isPopupLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isPopupLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {defaultStep && (
                <>
                  <StyledHeader>Scout confirmation</StyledHeader>
                  <StyledParagraph>
                    Each scouting mission costs $3,000.
                  </StyledParagraph>
                  <StyledParagraph> Do you want to proceed?</StyledParagraph>
                </>
              )}
              {NSF && !error && !defaultStep && (
                <>
                  <StyledHeader>Not enough funds.</StyledHeader>
                  <StyledParagraph>
                    We don't have sufficient funds to scout this player.
                  </StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              {!defaultStep && !error && !NSF && (
                <>
                  <StyledParagraph>Scout is out.</StyledParagraph>
                  <StyledParagraph>
                    We should hear back in {isInLeague ? '1 hour' : '4 hours'}{' '}
                    or so.
                  </StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {defaultStep ? (
                  <>
                    <StyledButton onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton primary onClick={sendScout}>
                      Proceed
                    </StyledButton>
                  </>
                ) : (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(ScoutPopup);
