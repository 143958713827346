import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePortal from 'react-useportal';
import {
  StyledContainer,
  StyledTaskItem,
  StyledTaskType,
  StyledTaskStatus,
  StyledTitle,
} from './styled';
import { getTrainingList } from '../../redux/actions/Training';
import { getFriendlyList } from '../../redux/actions/Friendly';

import { getLeaguesList } from '../../redux/actions/League';
import { showPopupWindow } from '../../redux/actions/Popups';

import { getTransferWindow } from '../../redux/selector/Transfers';
import { isLeaguesListLoaded } from '../../redux/selector/League';
import { getPopupByName } from '../../redux/selector/Popups';

import { FriendlyPopup, TrainingPopup } from '../Popup';
import { POPUPS } from '../../service/constants';

const TasksList = ({ tasksList }) => {
  const { TRAINING_POPUP, FRIENDLY_POPUP } = POPUPS;
  const leaguesList = useSelector(isLeaguesListLoaded);

  const dispatch = useDispatch();
  const history = useHistory();
  const friendlyPopup = useSelector(getPopupByName(FRIENDLY_POPUP));
  const trainingPopup = useSelector(getPopupByName(TRAINING_POPUP));

  const isTransferWindowOpen = useSelector(getTransferWindow);

  const handleTaskClick = (type) => {
    if (type === 0) {
      history.push('/team/strategy');
    }
    if (type === 1) {
      dispatch(showPopupWindow(TRAINING_POPUP, TRAINING_POPUP, true));
      dispatch(getTrainingList());
    }
    if (type === 2) {
      dispatch(showPopupWindow(FRIENDLY_POPUP, FRIENDLY_POPUP, true));
      dispatch(getFriendlyList());
    }
    if (type === 3) {
      history.push('/transfers/deals');
    }
    if (type === 4) {
      history.push('/youth-camp-signup');
    }
    if (type === 5) {
      history.push('/youth-camp');
    }
    if (type === 6) {
      history.push('/youth-camp');
    }
  };
  const taskTypes = {
    0: 'Set your team',
    1: 'Practice',
    2: 'Play friendly match',
    3: 'Buy/sell players',
    4: 'Register for camp',
    5: 'Scout youth players',
    6: 'Bid on youth players',
  };
  const taskStatuses = {
    0: 'Go',
    1: 'Done',
  };

  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });
  useEffect(() => {
    if (isEmpty(leaguesList)) {
      dispatch(getLeaguesList());
    }
  }, [dispatch, leaguesList]);

  return (
    <StyledContainer>
      {!isEmpty(trainingPopup) && (
        <Portal>
          <TrainingPopup popupName={TRAINING_POPUP} leaguesList={leaguesList} />
        </Portal>
      )}
      {!isEmpty(friendlyPopup) && (
        <Portal>
          <FriendlyPopup popupName={FRIENDLY_POPUP} leaguesList={leaguesList} />
        </Portal>
      )}
      <StyledTitle>TO DO</StyledTitle>
      {tasksList.map((task, index) => (
        <StyledTaskItem key={index}>
          <StyledTaskType disabled={task.type === 3 && !isTransferWindowOpen}>
            {taskTypes[task.type]}
          </StyledTaskType>
          <StyledTaskStatus
            onClick={() => handleTaskClick(task.type)}
            isTransferWindowClosed={task.type === 3 && !isTransferWindowOpen}
            disabled={
              task.status === 1 || (task.type === 3 && !isTransferWindowOpen)
            }
            status={task.status}
          >
            {taskStatuses[task.status]}
          </StyledTaskStatus>
        </StyledTaskItem>
      ))}
    </StyledContainer>
  );
};

export default TasksList;
