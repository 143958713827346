import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { previewLogin } from '../../redux/actions/User';
import { getMyCurrentTeam, isAuth } from '../../redux/selector/User';
import { StyledContainer, StyledNewLoader } from './styled';

export default function PreviewCallback() {
  const dispatch = useDispatch();
  const teamData = useSelector(getMyCurrentTeam);

  const isAuthenticated = useSelector(isAuth);
  const history = useHistory();
  useEffect(() => {
    dispatch(previewLogin());
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !isEmpty(teamData)) {
      const previewTeamData = localStorage.getItem('previewTeamData');
      if (!isEmpty(previewTeamData)) {
        history.push('/home');
      }
    }
  }, [teamData]);

  return (
    <StyledContainer>
      <StyledNewLoader type="Bars" color="#00BFFF" height={100} width={100} />
    </StyledContainer>
  );
}
