import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import YouthCampStepOne from '../../assets/img/youth-camp-step-1.png';
import YouthCampStepTwo from '../../assets/img/youth-camp-step-2.png';
import YouthCampStepThree from '../../assets/img/youth-camp-step-3.png';
import YouthCampNumberOne from '../../assets/img/youth-camp-number-1.png';
import YouthCampNumberTwo from '../../assets/img/youth-camp-number-2.png';
import YouthCampNumberThree from '../../assets/img/youth-camp-number-3.png';
import YouthCampSignUpHeader from '../../components/YouthCampSignUpHeader';
import BidForm from '../../components/BidForm';

import {
  StyledLoader,
  StyledLoginContainer,
  StyledLeftBlock,
  StyledRightBlock,
  StyledBG,
  StyledHeader,
  StyledFirstSection,
  StyledSecondSection,
  StyledThirdSection,
  StyledDescription,
  StyledText,
  StyledStepOne,
  StyledSecondSectionContainer,
  StyledStepOneTwo,
  StyledStepTwo,
  StyledStepTwoThree,
  StyledThirdSectionContainer,
  StyledStepThree,
  StyledNumberImg,
  StyledCanvasContainer,
  StyledMobileBlock,
} from './styled';

import {
  getYouthCampStatusRequest,
  isUserRegistered,
} from '../../redux/actions/YouthCamp';
import { sidebarGetDataForSideBar } from '../../redux/actions/Sidebar';

import {
  isYouthCampAvailable,
  getYouthCampDataLoading,
  isYCRegistered,
} from '../../redux/selector/YouthCamp';

const YouthCampSignUp = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isYouthCampAuth = useSelector(isYouthCampAvailable);
  const isRegistered = useSelector(isYCRegistered);
  const isLoading = useSelector(getYouthCampDataLoading);

  useEffect(() => {
    dispatch(sidebarGetDataForSideBar());
    if (isYouthCampAuth === null) {
      dispatch(getYouthCampStatusRequest());
    } else if (!isYouthCampAuth) {
      history.push('/home');
    }
    if (isRegistered) {
      history.push('/youth-camp');
    } else {
      dispatch(isUserRegistered());
    }
    /* eslint-disable-next-line */
  }, [dispatch, isYouthCampAuth, history, isRegistered, isUserRegistered]);

  return (
    <>
      {isLoading || !isYouthCampAuth ? (
        <StyledLoader type="Bars" color="#00BFFF" height={100} width={100} />
      ) : (
        <>
          <YouthCampSignUpHeader />
          <StyledLoginContainer>
            <StyledLeftBlock>
              <StyledMobileBlock>
                <BidForm number={1} bg="white" />
              </StyledMobileBlock>
              <StyledHeader>How It Works</StyledHeader>
              <StyledCanvasContainer>
                <StyledFirstSection>
                  <StyledStepOne src={YouthCampStepOne} alt="youth camp 1" />
                  <StyledStepOneTwo />
                  <StyledDescription>
                    <StyledNumberImg src={YouthCampNumberOne} alt="number 1" />
                    <StyledText>
                      Choose how many scouts to send to our 3-day youth camp.
                    </StyledText>
                  </StyledDescription>
                </StyledFirstSection>
                <StyledSecondSection>
                  <StyledSecondSectionContainer>
                    <StyledStepTwo src={YouthCampStepTwo} alt="youth camp 2" />
                    <StyledStepTwoThree />
                    <StyledDescription>
                      <StyledNumberImg
                        src={YouthCampNumberTwo}
                        alt="number 2"
                      />

                      <StyledText>
                        Each day, select which players you'll scout. The more
                        you scout, the more you learn.
                      </StyledText>
                    </StyledDescription>
                  </StyledSecondSectionContainer>
                </StyledSecondSection>
                <StyledThirdSection>
                  <StyledThirdSectionContainer>
                    <StyledStepThree
                      src={YouthCampStepThree}
                      alt="youth camp 3"
                    />
                    <StyledDescription>
                      <StyledNumberImg
                        src={YouthCampNumberThree}
                        alt="number 3"
                      />

                      <StyledText>
                        Bid on players daily. The highest bidder signs the
                        player!
                      </StyledText>
                    </StyledDescription>
                  </StyledThirdSectionContainer>
                </StyledThirdSection>
              </StyledCanvasContainer>
            </StyledLeftBlock>
            <StyledRightBlock>
              <StyledBG />
              <BidForm number={2} />
            </StyledRightBlock>
          </StyledLoginContainer>
        </>
      )}
    </>
  );
};

export default YouthCampSignUp;
