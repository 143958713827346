import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
  StyledRangeSelect,
  StyledRangeLabel,
  StyledPopupButton
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { transfersListRequest } from '../../../redux/actions/Transfers';
import { createNewContractOffer, extendExistingContractOffer } from '../../../redux/actions/Contracts';

import { dataForSidebar } from '../../../redux/selector/Sidebar';

import { getPopupByName } from '../../../redux/selector/Popups';

const ContractOfferPopup = ({ popupName, id, isExtended, isFreeAgentPopup, salary }) => {
  const minWage = ((salary / 7) + 10);
  const [step, changeStep] = useState('contract');
  const [wage, setWage] = useState(minWage);
  const [seasonsAmount, setSeasonsAmount] = useState(1);
  const [matchesAmount, setMatchesAmount] = useState(1);
  const [isFreeAgentInfo, showFreeAgentInfo] = useState(isFreeAgentPopup);
  // TODO break out to constants
  const steps = {
    contract: 'contract',
    complete: 'complete',
    error: 'error',
  };
  const dispatch = useDispatch();

  const { isLoading, error } = useSelector(getPopupByName(popupName));
  const { cash } = useSelector(dataForSidebar);

  const NSF = cash - ((salary / 7) + 10) < 0;

  React.useEffect(() => {
    if (!isEmpty(error)) {
      changeStep(steps.error);
    }
  }, [error, steps.error]);

  const moveToComplete = () => {
    if (!NSF) {
      if (isExtended) {
        dispatch(extendExistingContractOffer({ player: id, wage: parseInt(wage), playTime: parseInt(matchesAmount), length: parseInt(seasonsAmount) }));
        changeStep(steps.complete);
      }
      else {
        dispatch(createNewContractOffer({ player: id, wage: parseInt(wage), playTime: parseInt(matchesAmount), length: parseInt(seasonsAmount) }));
        changeStep(steps.complete);
      }
    }
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
      dispatch(transfersListRequest());
    }
  };

  const contractTitle = () => {

    switch (true) {
      case isExtended && !isFreeAgentPopup: return 'Contract Extension';
      case isFreeAgentPopup && !isExtended: return 'Free agent contract'
      default: return 'New contract'
    }
  }
  return (
    <StyledPopUp>
      <StyledPopUpBlock maxWidth={375}>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {step === steps.error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              {step === steps.contract && (
                isFreeAgentInfo ?
                  <>
                    <StyledHeader>Free agent fee</StyledHeader>
                    <StyledParagraph>
                      There is a $250,000 league fee for signing a free agent.
                    </StyledParagraph>
                    <StyledParagraph>Would you like to proceed?</StyledParagraph>
                  </>
                  :
                  <>
                    <StyledHeader>{contractTitle()}</StyledHeader>
                    <StyledRangeLabel>
                      Daily wage
                    </StyledRangeLabel>
                    <StyledRangeSelect
                      prefix="$"
                      callback={setWage}
                      minValue={+(Math.floor(salary / 7) + 10).toFixed()}
                      maxValue={+(Math.floor((salary / 7 + 10) * 5)).toFixed()}
                      stepAmount={+((Math.floor(salary / 7) + 10)).toFixed()}
                    />
                    <StyledRangeLabel>
                      Contract length
                    </StyledRangeLabel>
                    <StyledRangeSelect
                      stepAmount={1}
                      minValue={1}
                      maxValue={5}
                      callback={setSeasonsAmount}
                      thousandSeparator={false}
                      suffix={seasonsAmount > 1 ? " seasons" : " season"}
                      placeholder='0 seasons'
                    />
                    <StyledRangeLabel>
                      Play time
                    </StyledRangeLabel>
                    <StyledRangeSelect
                      thousandSeparator={false}
                      stepAmount={1}
                      minValue={1}
                      maxValue={24}
                      callback={setMatchesAmount}
                      placeholder="0 matches"
                      suffix={matchesAmount > 1 ? " matches" : " match"} />
                  </>
              )}
              {step === steps.complete && NSF && (
                <>
                  <StyledHeader>Not enough funds.</StyledHeader>
                  <StyledParagraph>
                    We don't have sufficient funds to make an offer.
                  </StyledParagraph>
                </>
              )}
              {step === steps.complete && !error && !NSF && (
                <>
                  <StyledHeader>Contract sent</StyledHeader>
                  <StyledParagraph>
                    Please wait for response
                  </StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {step === steps.contract && (
                  <>
                    <StyledPopupButton
                      customBgColor="#E5E5E5"
                      customFontColor="#CE4646"
                      small
                      onClick={() => hidePopup()}>
                      Cancel
                    </StyledPopupButton>
                    {isFreeAgentInfo ?
                      <StyledPopupButton primary onClick={() => showFreeAgentInfo(false)}>
                        Proceed
                      </StyledPopupButton>
                      :
                      <StyledPopupButton
                        disabled={seasonsAmount < 1 || seasonsAmount > 5 || matchesAmount > 24}
                        primary
                        onClick={() => {
                          moveToComplete();
                        }}
                      >
                        Make Offer
                      </StyledPopupButton>}
                  </>
                )}

                {(step === steps.error || step === steps.complete) && (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(ContractOfferPopup);
