import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isAndroid } from 'react-device-detect';
import usePortal from 'react-useportal';
import moment from 'moment';

import {
  StyledTeamSquadContainer,
  StyledCard,
  StyledCardDetailItem,
  StyledValue,
  StyledLabel,
  StyledPlayerData,
  StyledChartContainer,
  StyledTeamName,
  StyledInjuredLabel,
  StyledLeftLabel,
  StyldRightLabel,
  StyledPlus,
  StyledHeader,
  StyledButtonColumnContainer,
  StyledFullWidthButton,
  StyledInsight,
  StyledToggleTeamView,
  StyledDarkReactTooltip,
  StyledPatronLabel,
} from './styled';

import { ReleasePlayerPopup } from '../Popup';
import { DonutChart, GridChart } from '../Charts';
import { digitFormater } from '../../service/functions';
import {
  rangeColor,
  formRangeColor,
  formRangeStatus,
  simplifiedPosition,
  strategyIds,
  mindsetStatusToValue,
  transferFeelingColor,
  transferFeeling,
} from '../../service/data';
import { ratingRangeStatus, POPUPS } from '../../service/constants';
import { hidePopupWindow } from '../../redux/actions/Popups';
import {
  teamAddToList,
  showMobileSidebarSection,
} from '../../redux/actions/Team';
import { sidebarOpenRightPanel } from '../../redux/actions/Sidebar';
import { getPopupsList } from '../../redux/selector/Popups';
import { dataForSidebar } from '../../redux/selector/Sidebar';
import { isPremiumUser } from '../../redux/selector/User';

import { getSeasonNumber } from '../../redux/selector/Team';

import { ReactComponent as Crown } from '../../assets/img/crown.svg';

export default function PlayerStatCard({
  playerWhoWasSold,
  isMobile,
  academyList = [],
}) {
  const {
    _id,
    first_name,
    last_name,
    rating_range,
    media_rating,
    recovery_time,
    fav_position,
    previous_rating,
    age,
    potential_range,
    goals_scored,
    assists,
    played_matches,
    form_range,
    salary,
    team,
    life_stage,
    played_matches_total,
    potential_min,
    potential_max,
    worst_mindset,
    fav_opponent,
    worst_opponent,
    fav_formation,
    fav_mindset,
    second_fav_position,
    transfer_feeling,
    contract
  } = playerWhoWasSold;
  const seasons = contract?.data?.length;
  const seasonsCount = contract?.current_stats.seasons_count;
  const seasonsLeft = seasons - seasonsCount;
  const { RELEASE_PLAYER_POPUP, PLAYER_INFO_POPUP_VIEW } = POPUPS;
  const rating = ratingRangeStatus[rating_range];
  const [isShowingOverview, changeCardOverview] = React.useState(true);

  const freeAgentPopupData = useSelector(getPopupsList);
  const isPremium = useSelector(isPremiumUser);
  const seasonNumber = useSelector(getSeasonNumber);
  const { cash } = useSelector(dataForSidebar);

  const dispatch = useDispatch();
  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  const goToPatreon = () => {
    if (window.navigator.userAgent.includes('wv') && isAndroid) {
      window.open(
        'inappsubscription://?package=com.example.app.webview-master&successful_url=https://www.theopenleague.com/home&expired_url=https://www.yahoo.com',
        '_blank'
      );
    } else {
      window.open('https://www.patreon.com/join/theopenleague?', '_blank');
    }
  };

  const setPlayerInfo = (player) => {
    dispatch(hidePopupWindow(PLAYER_INFO_POPUP_VIEW));
    dispatch(sidebarOpenRightPanel(true));
    dispatch(teamAddToList(player));
  };
  const showPlayersList = () => {
    dispatch(hidePopupWindow(PLAYER_INFO_POPUP_VIEW));
    dispatch(showMobileSidebarSection('list'));
  };
  const getRecoveryDate = (date) => {
    const eventdate = moment(date).utcOffset('-0000').add(1, 'days');
    const todaysdate = moment();
    const daysLeft = eventdate.diff(todaysdate, 'days');
    return `${daysLeft < 1 ? 1 : daysLeft} day${daysLeft > 1 ? 's' : ''}`;
  };
  const teamName = () => {
    if (academyList.includes(_id)) {
      return;
    }
    if (team && life_stage !== 3) {
      return team.name;
    }
    if (team && life_stage === 3) {
      return 'Retired';
    }

    return 'Free agent';
  };
  if (playerWhoWasSold) {
    const reverseRating = [...media_rating];
    reverseRating.reverse();
    const reversePrevRating = [...previous_rating];
    reversePrevRating.reverse();
    const formRange = formRangeStatus[form_range];
    const potentialRange = ratingRangeStatus[potential_range];
    return (
      <>
        {freeAgentPopupData[RELEASE_PLAYER_POPUP] && (
          <Portal>
            <ReleasePlayerPopup
              cash={cash}
              popupName={RELEASE_PLAYER_POPUP}
              id={_id}
              salary={salary}
              seasonsLeft={seasonsLeft}
            />
          </Portal>
        )}
        <StyledTeamSquadContainer>
          <StyledHeader>
            <h3>
              {first_name} {last_name}
            </h3>
            <StyledTeamName>{teamName()}</StyledTeamName>
            <StyledToggleTeamView className="formations-section">
              <button
                className={isShowingOverview ? 'active' : ''}
                onClick={() => changeCardOverview(true)}
                type="button"
              >
                Player Overview
              </button>
              <button
                disabled={!isPremium}
                className={!isShowingOverview ? 'active' : ''}
                onClick={() => changeCardOverview(false)}
                type="button"
              >
                <StyledDarkReactTooltip
                  globalEventOff="click"
                  id="insights"
                  type="dark"
                  clickable
                  effect="solid"
                  place="bottom"
                >
                  <StyledPatronLabel onClick={() => goToPatreon()}>
                    Become a patron
                  </StyledPatronLabel>{' '}
                  for easier access to player preferences (e.g., favorite
                  mindset, worst opponent, etc.)
                </StyledDarkReactTooltip>
                <StyledInsight
                  {...(!isPremium && {
                    'data-tip': true,
                    'data-event': 'click',
                    clickable: true,
                    'data-for': 'insights',
                  })}
                >
                  Insights
                  <Crown />
                </StyledInsight>
              </button>
            </StyledToggleTeamView>
          </StyledHeader>
          <StyledCard>
            <>
              {recovery_time && (
                <StyledInjuredLabel type="injured">
                  <StyledLeftLabel>
                    <StyledPlus>+</StyledPlus>Player is injured
                  </StyledLeftLabel>
                  <StyldRightLabel>
                    Returns in: {getRecoveryDate(recovery_time)}
                  </StyldRightLabel>
                </StyledInjuredLabel>
              )}
              {academyList.includes(_id) && (
                <StyledInjuredLabel type="academy">
                  <StyledLeftLabel>Academy</StyledLeftLabel>
                  <StyldRightLabel>
                    Returns at the end of season
                  </StyldRightLabel>
                </StyledInjuredLabel>
              )}
              {isShowingOverview ? (
                <>
                  <StyledPlayerData>
                    <StyledCardDetailItem half topBorder>
                      <>
                        <StyledValue>
                          {simplifiedPosition[fav_position] || 'N/A'}
                        </StyledValue>
                        <StyledLabel>Position</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                    <StyledCardDetailItem half topBorder>
                      <>
                        <StyledValue>{age || 'N/A'}</StyledValue>
                        <StyledLabel>Age</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                  </StyledPlayerData>
                  <StyledPlayerData>
                    <StyledCardDetailItem>
                      <>
                        <StyledValue color={rangeColor[rating]}>
                          {rating || 'N/A'}
                        </StyledValue>
                        <StyledLabel>Rating</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                    <StyledCardDetailItem>
                      <>
                        <StyledValue color={rangeColor[potentialRange]}>
                          {potentialRange || 'N/A'}
                        </StyledValue>
                        <StyledLabel>Potential</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                    <StyledCardDetailItem>
                      <>
                        <StyledValue color={formRangeColor[formRange]}>
                          {formRange || 'N/A'}
                        </StyledValue>
                        <StyledLabel>Fitness</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                  </StyledPlayerData>
                  {!isMobile ? (
                    <>
                      <StyledChartContainer>
                        {reverseRating.map((item, index) => (
                          <DonutChart
                            key={`${item}${index}`}
                            index={index + 1}
                            arcSize={item}
                          />
                        ))}
                      </StyledChartContainer>
                      <StyledChartContainer>
                        <GridChart
                          currentRating={rating}
                          seasonNumber={seasonNumber}
                          data={reversePrevRating}
                        />
                      </StyledChartContainer>
                      <StyledPlayerData>
                        <StyledCardDetailItem>
                          <>
                            <StyledValue>
                              {Number.isInteger(goals_scored)
                                ? goals_scored
                                : '-'}
                            </StyledValue>
                            <StyledLabel>Goals</StyledLabel>
                          </>
                        </StyledCardDetailItem>
                        <StyledCardDetailItem>
                          <>
                            <StyledValue>
                              {Number.isInteger(assists) ? assists : '-'}
                            </StyledValue>
                            <StyledLabel>Assists</StyledLabel>
                          </>
                        </StyledCardDetailItem>
                        <StyledCardDetailItem>
                          {!team ? (
                            <>
                              <StyledValue>
                                {Number.isInteger(played_matches_total)
                                  ? played_matches_total
                                  : '-'}
                              </StyledValue>
                              <StyledLabel>Career starts</StyledLabel>
                            </>
                          ) : (
                            <>
                              <StyledValue>
                                {Number.isInteger(played_matches)
                                  ? played_matches
                                  : '-'}
                              </StyledValue>
                              <StyledLabel>Starts</StyledLabel>
                            </>
                          )}
                        </StyledCardDetailItem>
                      </StyledPlayerData>
                      <StyledPlayerData>
                        <StyledCardDetailItem>
                          <>
                            <StyledValue>
                              {!team || (potential_min === -1 && potential_max === -1)
                                ? 'N/A'
                                : `$${digitFormater(
                                  potential_min
                                )}-${digitFormater(potential_max)}`}
                            </StyledValue>
                            <StyledLabel>Est. Value</StyledLabel>
                          </>
                        </StyledCardDetailItem>
                        <StyledCardDetailItem>
                          <>
                            <StyledValue>
                              ${digitFormater(salary / 7, 0) || '-'}
                            </StyledValue>
                            <StyledLabel>Daily Salary</StyledLabel>
                          </>
                        </StyledCardDetailItem>
                      </StyledPlayerData>
                    </>
                  ) : (
                    <StyledButtonColumnContainer>
                      <StyledFullWidthButton
                        primary
                        onClick={() => {
                          showPlayersList();
                        }}
                      >
                        Replace Player
                      </StyledFullWidthButton>
                      <StyledFullWidthButton
                        secondary
                        onClick={() => {
                          setPlayerInfo(playerWhoWasSold);
                        }}
                      >
                        See player profile
                      </StyledFullWidthButton>
                    </StyledButtonColumnContainer>
                  )}
                </>
              ) : (
                <>
                  <StyledPlayerData>
                    <StyledCardDetailItem topBorder half>
                      <>
                        <StyledValue>
                          {isPremium && strategyIds[fav_formation]}
                        </StyledValue>
                        <StyledLabel>Favourite Formation</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                    <StyledCardDetailItem topBorder half>
                      <>
                        <StyledValue>
                          {isPremium && simplifiedPosition[second_fav_position]}
                        </StyledValue>
                        <StyledLabel>Alternate Position</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                  </StyledPlayerData>
                  <StyledPlayerData>
                    <StyledCardDetailItem half>
                      <>
                        <StyledValue color="#00996b">
                          {isPremium && mindsetStatusToValue[fav_mindset]}
                        </StyledValue>
                        <StyledLabel>Favourite Mindset</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                    <StyledCardDetailItem half>
                      <>
                        <StyledValue color="#d63c30">
                          {isPremium && mindsetStatusToValue[worst_mindset]}
                        </StyledValue>
                        <StyledLabel>Worst Mindset</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                  </StyledPlayerData>
                  <StyledPlayerData>
                    <StyledCardDetailItem>
                      <>
                        <StyledValue color="#00996b">
                          {isPremium && fav_opponent}
                        </StyledValue>
                        <StyledLabel>Favourite Opponent</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                  </StyledPlayerData>
                  <StyledPlayerData>
                    <StyledCardDetailItem>
                      <>
                        <StyledValue color="#d63c30">
                          {isPremium && worst_opponent}
                        </StyledValue>
                        <StyledLabel>Worst Opponent</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                  </StyledPlayerData>
                  {Number(transfer_feeling) ? (
                    <StyledPlayerData>
                      <StyledCardDetailItem>
                        <>
                          <StyledValue
                            color={transferFeelingColor[transfer_feeling]}
                          >
                            {isPremium && transferFeeling[transfer_feeling]}
                          </StyledValue>
                          <StyledLabel>Club Fit</StyledLabel>
                        </>
                      </StyledCardDetailItem>
                    </StyledPlayerData>
                  ) : null}
                </>
              )}
            </>
          </StyledCard>
        </StyledTeamSquadContainer>
      </>
    );
  }
  return null;
}
