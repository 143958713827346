import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';

import { setToAcademyRequest } from '../../../redux/actions/Team';

import { getTeamSquad } from '../../../redux/selector/Team';
import { getPopupByName } from '../../../redux/selector/Popups';

const ConfirmAcademyPopup = ({ cash, popupName, id }) => {
  const [defaultStep, showDefaultStep] = useState(true);
  const NSF = cash - 100000 < 0;

  const { isPopupLoading } = useSelector(getTeamSquad);
  const { error, isLoading } = useSelector(getPopupByName(popupName));
  useEffect(() => {
    showDefaultStep(true);
  }, []);
  const dispatch = useDispatch();
  const sendRelease = () => {
    showDefaultStep(false);
    if (!NSF) {
      dispatch(setToAcademyRequest(id));
    }
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isPopupLoading || isLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {defaultStep && (
                <>
                  <StyledHeader>Send player to our Academy</StyledHeader>
                  <StyledParagraph>
                    Sending a player will cost{' '}
                    <b>$100,000 per day per player</b>. Players won’t be able to
                    play in our senior squad until they return at the end of the
                    season. They will not count against our roster size limit.
                    Also, not all players will succesfully develop at the
                    Academy.
                  </StyledParagraph>
                </>
              )}
              {NSF && !error && !defaultStep && (
                <>
                  <StyledHeader> Not enough funds.</StyledHeader>
                  <StyledParagraph>You have insufficient funds</StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              {!defaultStep && !error && !NSF && !isLoading && (
                <>
                  <StyledHeader>Thank you.</StyledHeader>
                  <StyledParagraph>
                    Player was sent successfuly to our academy
                  </StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {defaultStep ? (
                  <>
                    <StyledButton onClick={() => hidePopup()}>
                      Back
                    </StyledButton>
                    <StyledButton primary onClick={sendRelease}>
                      Confirm
                    </StyledButton>
                  </>
                ) : (
                  <StyledButton primary onClick={() => hidePopup()}>
                    OK, I understand
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default ConfirmAcademyPopup;
