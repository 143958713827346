import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { sendTransferReject } from '../../../redux/actions/Transfers';
import { getPopupByName } from '../../../redux/selector/Popups';

const RejectOfferPopup = ({ data, popupName }) => {
  const [defaultStep, showDefaultStep] = useState(true);
  const { error, isLoading } = useSelector(getPopupByName(popupName));

  useEffect(() => {
    showDefaultStep(true);
  }, []);
  const dispatch = useDispatch();
  const rejectTransfer = () => {
    showDefaultStep(false);
    dispatch(sendTransferReject(data._id));
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {defaultStep && (
                <>
                  <StyledHeader>{data.cardType} Offer</StyledHeader>

                  <StyledParagraph>
                    Are you sure you want to reject this offer?
                  </StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {defaultStep ? (
                  <>
                    <StyledButton basic onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton primary onClick={() => rejectTransfer()}>
                      Reject offer
                    </StyledButton>
                  </>
                ) : (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};
RejectOfferPopup.propTypes = {
  data: PropTypes.object.isRequired,
  popupName: PropTypes.string.isRequired,
};
export default React.memo(RejectOfferPopup);
