import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledButton } from '../../../globalStyled';
import { numberWithCommas } from '../../../service/utils';
import { transferSetCreateNewOfferSearch } from '../../../redux/actions/Transfers';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
  StyledRangeSelect,
  StyledRedAlert,
} from '../styled';

import { hidePopupWindow } from '../../../redux/actions/Popups';

import { getPopupByName } from '../../../redux/selector/Popups';

const BuyOfferPopup = ({
  playerId,
  playerName,
  popupName,
  team,
  minPotentialValue,
  maxPotentialValue,
}) => {
  const DEFAULT_STEP = 'default';
  const CONFIRMATION_STEP = 'confirmation';
  const COMPLETE = 'complete';
  const [step, showStep] = useState(DEFAULT_STEP);

  const [buyAmount, setBuyAmount] = useState(0);
  const { error, isLoading } = useSelector(getPopupByName(popupName));
  const dispatch = useDispatch();

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  const checkCallback = (e) => {
    setBuyAmount(e);
  };

  const confirmBuy = () => {
    showStep(CONFIRMATION_STEP);
  };
  const approveBuy = () => {
    showStep(COMPLETE);
    dispatch(transferSetCreateNewOfferSearch(playerId, buyAmount));
  };

  const hasLowerOffer = parseInt(buyAmount) < minPotentialValue;
  const hasHigherOffer = parseInt(buyAmount) > maxPotentialValue;

  const noOfferLimit = !hasLowerOffer && !hasHigherOffer;
  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer noOverflow>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {step === DEFAULT_STEP && !error && (
                <>
                  <StyledHeader>Buy Offer</StyledHeader>
                  <StyledParagraph>
                    You are offering to pay this amount to transfer this player
                    to your club.
                  </StyledParagraph>
                  <StyledRangeSelect
                    prefix="$"
                    className="loan-amount"
                    callback={(e) => checkCallback(e)}
                    stepAmount={1000000}
                  />
                </>
              )}
              {step === CONFIRMATION_STEP && !error && hasLowerOffer && (
                <>
                  <StyledHeader>Low offer confirmation</StyledHeader>
                  <StyledParagraph>
                    Please confirm that you want to offer{' '}
                    <StyledRedAlert>
                      ${numberWithCommas(buyAmount)}
                    </StyledRedAlert>
                    . The amount offered is{' '}
                    <StyledRedAlert>much lower</StyledRedAlert> than most offers
                    for similar players.
                  </StyledParagraph>
                  <StyledParagraph>
                    It is highly recommended to negotiate offers with sellers on
                    Discord before making formal offers.
                  </StyledParagraph>
                </>
              )}
              {step === CONFIRMATION_STEP && !error && hasHigherOffer && (
                <>
                  <StyledHeader>High offer confirmation</StyledHeader>
                  <StyledParagraph>
                    Please confirm that you want to offer{' '}
                    <StyledRedAlert>
                      ${numberWithCommas(buyAmount)}
                    </StyledRedAlert>
                    . The amount offered is{' '}
                    <StyledRedAlert>much higher</StyledRedAlert> than most
                    offers for similar players.
                  </StyledParagraph>
                  <StyledParagraph>
                    It is highly recommended to negotiate offers with sellers on
                    Discord before making formal offers.
                  </StyledParagraph>
                </>
              )}
              {step === CONFIRMATION_STEP && !error && noOfferLimit && (
                <>
                  <StyledHeader>Offer confirmation</StyledHeader>

                  <StyledParagraph>
                    You are sending a buy offer for{' '}
                    <strong>{playerName}</strong>. You will pay{' '}
                    <strong>{team} </strong>
                    the total sum of{' '}
                    <strong>${numberWithCommas(buyAmount)}</strong> for the
                    transfer. Do you confirm?
                  </StyledParagraph>
                </>
              )}
              {step === COMPLETE && !error && (
                <>
                  <StyledHeader>Offer sent</StyledHeader>
                  <StyledParagraph>
                    Waiting for the club to respond
                  </StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {step === DEFAULT_STEP && !error && (
                  <>
                    <StyledButton basic onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton
                      primary
                      disabled={buyAmount < 1}
                      onClick={() => confirmBuy()}
                    >
                      Send Offer
                    </StyledButton>
                  </>
                )}
                {step === CONFIRMATION_STEP && !error && (
                  <>
                    <StyledButton basic onClick={() => showStep(DEFAULT_STEP)}>
                      Back
                    </StyledButton>
                    <StyledButton
                      primary={noOfferLimit}
                      danger={
                        buyAmount > 0 && (hasLowerOffer || hasHigherOffer)
                      }
                      onClick={() => approveBuy()}
                    >
                      {buyAmount > 0 && (hasLowerOffer || hasHigherOffer)
                        ? 'Send offer anyway'
                        : 'Send Offer'}
                    </StyledButton>
                  </>
                )}
                {error && (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};
BuyOfferPopup.propTypes = {
  playerId: PropTypes.string.isRequired,
  playerName: PropTypes.string.isRequired,
  popupName: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
};
export default React.memo(BuyOfferPopup);
