import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
} from '../styled';
import BidSelect from '../../BidSelect';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { bidYouthCampRequest } from '../../../redux/actions/YouthCamp';

import { dataForSidebar } from '../../../redux/selector/Sidebar';
import {
  youthCampPopupDataLoading,
  getYouthCampDetails,
} from '../../../redux/selector/YouthCamp';
import { getPopupByName } from '../../../redux/selector/Popups';

const BidPopup = ({ popupName, id }) => {
  const [step, changeStep] = useState('bid');
  const [bidVal, getBidValue] = useState(1);
  // TODO break out to constants
  const steps = {
    bid: 'bid',
    confirm: 'confirm',
    complete: 'complete',
    error: 'error',
  };
  const dispatch = useDispatch();
  const isLoading = useSelector(youthCampPopupDataLoading);
  const { bidsRemaining } = useSelector(getYouthCampDetails);
  const bidPopupData = useSelector(getPopupByName(popupName));
  const { cash } = useSelector(dataForSidebar);

  const NSF = cash - 500000 < 0;

  const { error } = bidPopupData;
  React.useEffect(() => {
    if (!isEmpty(error)) {
      changeStep(steps.error);
    }
  }, [error, steps.error]);
  const moveToConfirm = () => {
    changeStep(steps.confirm);
  };
  const moveToComplete = () => {
    if (!NSF) {
      dispatch(bidYouthCampRequest(bidVal, id));
      changeStep(steps.complete);
    }
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {step === steps.error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              {step === steps.bid && (
                <>
                  <StyledHeader>Bid on player</StyledHeader>
                  <StyledParagraph>
                    You have <b>{bidsRemaining} points</b> available. How many
                    points would you like to bid to acquire this player?
                  </StyledParagraph>
                  <BidSelect
                    minLimitValue={1}
                    maxLimitValue={bidsRemaining}
                    callback={(val) => {
                      getBidValue(val);
                    }}
                  />
                </>
              )}
              {step === steps.complete && NSF && (
                <>
                  <StyledHeader>Not enough funds.</StyledHeader>
                  <StyledParagraph>
                    We don't have sufficient funds to make bid.
                  </StyledParagraph>
                </>
              )}
              {step === steps.confirm && !NSF && (
                <>
                  <StyledHeader>Confirm bid</StyledHeader>
                  <StyledParagraph>
                    If you are the highest bidder for this player, you will be
                    assessed an automatic <b>$500,000</b> transfer fee. Bids are
                    final.
                  </StyledParagraph>
                  <StyledParagraph> Do you want to proceed?</StyledParagraph>
                </>
              )}
              {step === steps.complete && !error && !NSF && (
                <>
                  <StyledHeader>Bid sent</StyledHeader>
                  <StyledParagraph>
                    Bid results will be announced at 12pm.
                  </StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {step === steps.bid && (
                  <>
                    <StyledButton onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton
                      disabled={bidVal < 1}
                      primary
                      onClick={() => {
                        moveToConfirm();
                      }}
                    >
                      Bid
                    </StyledButton>
                  </>
                )}
                {step === steps.confirm && (
                  <>
                    <StyledButton onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton
                      primary
                      onClick={() => {
                        moveToComplete();
                      }}
                    >
                      Confirm
                    </StyledButton>
                  </>
                )}
                {(step === steps.error || step === steps.complete) && (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(BidPopup);
