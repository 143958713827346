import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import YouTube from 'react-youtube';
import {
  StyledPageWrapper,
  StyledHeader,
  StyledSection,
  StyledLeftHeaderSection,
  StyledRightHeaderSection,
  StyledDarkSection,
  StyledTitle,
  StyledSubtitle,
  StyledLandingButton,
  StyledButtonsContainer,
  StyledDetailsContainer,
  StyledContainerLoader,
  StyledLoader,
  StyledSidebarContainer,
  StyledMainContainer,
  StyledSectionTitle,
  StyledParagraph,
  StyledSectionSubtitle,
  StyledList,
  StyledUnderline,
  StyledListItem,
  StyledSeparator,
} from './styled';
import { ReactComponent as LOGO } from '../../assets/img/tol-logo.svg';
import { ReactComponent as LOGOMobile } from '../../assets/img/tol-logo-mobile.svg';
import LandingBig from '../../assets/img/landing-big.png';
import LandingSmall from '../../assets/img/landing-small.png';
import { testAuth } from '../../redux/actions/User';
import { isAuth, showLoader } from '../../redux/selector/User';
import { campaignParams } from '../../service/mixpanel_utm';
import PlayerCard from '../../assets/img/press-kit/Player-card.png';
import dalleM from '../../assets/img/press-kit/dalle-m.png';
import Crests from '../../assets/img/press-kit/Crests.png';
import Discord1 from '../../assets/img/press-kit/Discord1.png';
import Discord2 from '../../assets/img/press-kit/Discord2.png';
import Jerseys from '../../assets/img/press-kit/Jerseys.png';
import Lobby from '../../assets/img/press-kit/Lobby.png';
import LogoTransparent from '../../assets/img/press-kit/logo-transprent.png';
import LogoBlack from '../../assets/img/press-kit/logo-black.png';

const PressKitPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(isAuth);
  const isLoading = useSelector(showLoader);
  const env = runtimeEnv();
  const { REACT_APP_AUTH_LINK } = env;
  React.useEffect(() => {
    if (isAuthenticated === null) {
      dispatch(testAuth());
    }
    if (isAuthenticated) {
      history.push('/home');
    }
  }, [isAuthenticated, dispatch, history]);

  React.useEffect(() => {
    campaignParams();
  }, []);

  const clickLogin = () => {
    mixpanel.track('Login clicked');
    if (isAuthenticated) {
      history.push('/home');
    } else {
      window.location.href = REACT_APP_AUTH_LINK;
    }
  };

  const clickRegister = () => {
    mixpanel.track('Register button clicked');
    localStorage.setItem('isFromRegister', true);
    history.push('/signup');
  };

  return (
    <>
      {isLoading || isAuthenticated === null ? (
        <StyledContainerLoader>
          <StyledLoader type="Bars" color="#00BFFF" height={100} width={100} />
        </StyledContainerLoader>
      ) : (
        <StyledPageWrapper>
          <StyledDarkSection>
            <img
              src={isMobile ? LandingSmall : LandingBig}
              alt="Online Soccer Game"
            />
            <StyledHeader>
              <StyledLeftHeaderSection>
                {isMobile ? <LOGOMobile /> : <LOGO />}
              </StyledLeftHeaderSection>
              <StyledRightHeaderSection>
                <StyledButtonsContainer>
                  <StyledLandingButton
                    id="register-link"
                    primary
                    small
                    onClick={clickRegister}
                  >
                    Register
                  </StyledLandingButton>
                  <StyledLandingButton
                    small
                    onClick={() => {
                      clickLogin();
                    }}
                  >
                    Log In
                  </StyledLandingButton>
                </StyledButtonsContainer>
              </StyledRightHeaderSection>
            </StyledHeader>
            <StyledSection>
              <StyledSubtitle>
                The Open League is a free online football manager game fully
                integrated with Discord
              </StyledSubtitle>
              <StyledTitle>
                The football manager game to play with friends
                <br />
              </StyledTitle>
            </StyledSection>
          </StyledDarkSection>
          <StyledDetailsContainer>
            <StyledSidebarContainer>
              <StyledSectionTitle>Factsheet</StyledSectionTitle>
              <StyledSectionSubtitle>Developer</StyledSectionSubtitle>
              <StyledParagraph small>Squiggly Games</StyledParagraph>
              <StyledSectionSubtitle>Release date</StyledSectionSubtitle>
              <StyledParagraph small>
                January 2023 (Public Beta)
              </StyledParagraph>

              <StyledSectionSubtitle>Platforms</StyledSectionSubtitle>
              <StyledParagraph small>
                Browser
                <br />
                Steam PC/Mac
              </StyledParagraph>

              <StyledSectionSubtitle>Website</StyledSectionSubtitle>
              <StyledParagraph small>https://theopenleague.com</StyledParagraph>

              <StyledSectionSubtitle>Price</StyledSectionSubtitle>
              <StyledParagraph small>
                Free to play
                <br /> $6/month for convenience features
              </StyledParagraph>
            </StyledSidebarContainer>
            <StyledMainContainer>
              <StyledSectionTitle>Description</StyledSectionTitle>
              <StyledParagraph big>
                The Open League is a football (soccer) manager game for fans of
                the beautiful game who love the Football Manager series but
                don’t love playing alone or who are looking for something less
                involved / complex. This game is about trading, tweaking,
                testing, and, of course, bragging.
              </StyledParagraph>
              <StyledSectionTitle>History</StyledSectionTitle>
              <StyledParagraph big>
                The Open League was started in early 2020 when Kote got stuck at
                home with too much time on his hands and not enough football on
                TV. He got a few friends together and started playing different
                prototypes of a football manager game using text messages,
                spreadsheets and streaming "live" matches on Twitter on
                Saturdays. Maysara joined Kote not long after and together,
                along with a few passionate early users, they started building
                The Open League.
              </StyledParagraph>
              <StyledSectionTitle>Features</StyledSectionTitle>
              <StyledParagraph big>
                <StyledList>
                  Features that are <StyledUnderline>unique</StyledUnderline> to
                  TOL in the football manager genre:
                  <StyledListItem>Simple, accessible, gameplay</StyledListItem>
                  <StyledListItem>
                    Fully integrated with Discord. Play-by-play match highlights
                    are streamed into Discord channels in real time
                  </StyledListItem>
                  <StyledListItem>
                    Continuously integrating new AI services to incorporate to
                    create unique assets and dialogues
                  </StyledListItem>
                  <StyledListItem>Players are genderless</StyledListItem>
                </StyledList>
              </StyledParagraph>
              <StyledParagraph big>
                <StyledList>
                  Other features:
                  <StyledListItem>
                    Each server is self-contained and includes 30 unique teams
                    and ~5000 players; teams are organized in three leagues of
                    ten teams
                  </StyledListItem>
                  <StyledListItem>
                    The top three teams get promoted and the bottom three teams
                    get demoted each season
                  </StyledListItem>
                  <StyledListItem>
                    A season lasts three weeks. The offseason lasts three days.
                    Players age, grow, decay and retire. Users keep their team
                    as long as they want
                  </StyledListItem>
                  <StyledListItem>
                    Matches are simulated nightly at 7pm - they last 2x45
                    minutes
                  </StyledListItem>
                  <StyledListItem>
                    Teams are managed by humans (for the most part)
                  </StyledListItem>
                  <StyledListItem>
                    Users choose which formation, players, mindset, and level of
                    efforts they play for each match. They can make
                    substitutions and strategy changes during matches
                  </StyledListItem>
                  <StyledListItem>
                    Managers negotiate transfers with other users on Discord and
                    formalize deals in their club’s web portal
                  </StyledListItem>
                  <StyledListItem>
                    Clubs go to youth camps during the offseason to scout and
                    sign the hottest new talent
                  </StyledListItem>
                </StyledList>
              </StyledParagraph>
              <StyledSectionTitle>Credits</StyledSectionTitle>
              <StyledParagraph big>
                <StyledList>
                  <StyledListItem>
                    Kote Flosse: Community, Game Design, Business
                  </StyledListItem>
                  <StyledListItem>
                    @null: Lead developer, backend
                  </StyledListItem>
                  <StyledListItem>
                    Serhii Kryzhanovskyi: Developer, frontend
                  </StyledListItem>
                </StyledList>
              </StyledParagraph>
              <StyledSectionTitle>Contact</StyledSectionTitle>
              <StyledParagraph big>
                All inquiries: Kote@squigglygames.com
              </StyledParagraph>
              <StyledSeparator />
              <StyledSectionTitle>Videos</StyledSectionTitle>
              <YouTube className="youtube-container" videoId="eStMsDgTck4" />

              <StyledSectionTitle>Images</StyledSectionTitle>
              <StyledParagraph>
                <a
                  href={LogoTransparent}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img alt="Logo Transparent" src={LogoTransparent} />
                </a>
                <a href={LogoBlack} rel="noopener noreferrer" target="_blank">
                  <img alt="Logo Black" src={LogoBlack} />
                </a>
                <a href={Lobby} rel="noopener noreferrer" target="_blank">
                  <img alt="Lobby" src={Lobby} />
                </a>
                <a href={PlayerCard} rel="noopener noreferrer" target="_blank">
                  <img alt="Player card" src={PlayerCard} />
                </a>
                <a href={Discord1} rel="noopener noreferrer" target="_blank">
                  <img alt="Discord" src={Discord1} />
                </a>
                <a href={Discord2} rel="noopener noreferrer" target="_blank">
                  <img alt="Discord" src={Discord2} />
                </a>
                <a href={Crests} rel="noopener noreferrer" target="_blank">
                  <img alt="Crests" src={Crests} />
                </a>
                <a href={Jerseys} rel="noopener noreferrer" target="_blank">
                  <img alt="Jerseys" src={Jerseys} />
                </a>
                <a href={dalleM} rel="noopener noreferrer" target="_blank">
                  <img alt="Dalle M" src={dalleM} />
                </a>
              </StyledParagraph>
            </StyledMainContainer>
          </StyledDetailsContainer>
        </StyledPageWrapper>
      )}
    </>
  );
};

export default PressKitPage;
