import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { sendTransferCancel } from '../../../redux/actions/Transfers';
import { getPopupByName } from '../../../redux/selector/Popups';

const CancelOfferPopup = ({ data, popupName }) => {
  const [defaultStep, showDefaultStep] = useState(true);
  const { error, isLoading } = useSelector(getPopupByName(popupName));

  useEffect(() => {
    showDefaultStep(true);
  }, []);
  const dispatch = useDispatch();
  const cancelTransfer = () => {
    showDefaultStep(false);
    dispatch(sendTransferCancel(data._id));
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {defaultStep && (
                <>
                  <StyledHeader>
                    {data.cardType === 'Sell' || data.cardType === 'Buy'
                      ? data.cardType
                      : 'Loan'}{' '}
                    player cancelation
                  </StyledHeader>

                  <StyledParagraph>
                    Are you sure you want to cancel this offer?
                  </StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {defaultStep ? (
                  <>
                    <StyledButton basic onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton primary onClick={cancelTransfer}>
                      Confirm
                    </StyledButton>
                  </>
                ) : (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};
CancelOfferPopup.propTypes = {
  popupName: PropTypes.string.isRequired,
};
export default React.memo(CancelOfferPopup);
