import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { numberWithCommas } from '../../../service/utils';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
} from '../styled';

import { hidePopupWindow } from '../../../redux/actions/Popups';
import {
  youthCampRegisterRequest,
  setYouthCampScoutStatus,
} from '../../../redux/actions/YouthCamp';

import { dataForSidebar } from '../../../redux/selector/Sidebar';
import { getPopupByName } from '../../../redux/selector/Popups';
import {
  youthCampPopupDataLoading,
  getYouthCampStatus,
} from '../../../redux/selector/YouthCamp';

const YouthCampScoutPopup = ({ popupName, scoutVal, id }) => {
  const [step, changeStep] = useState('confirm');
  // TODO break out to constants
  const steps = {
    confirm: 'confirm',
    complete: 'complete',
    error: 'error',
  };
  const history = useHistory();
  const dispatch = useDispatch();

  const isPopupLoading = useSelector(youthCampPopupDataLoading);
  const youthCampStatus = useSelector(getYouthCampStatus);
  const youthCampScoutPopupData = useSelector(getPopupByName(popupName));
  const { cash } = useSelector(dataForSidebar);

  const NSF = cash - parseInt(scoutVal * 100000) < 0;
  const shouldRedirect = youthCampStatus === 402 || youthCampStatus === 200;

  useEffect(() => {
    if (shouldRedirect) {
      changeStep(steps.complete);
    }
  }, [shouldRedirect, steps.complete]);

  const { error } = youthCampScoutPopupData;
  React.useEffect(() => {
    if (!isEmpty(error)) {
      changeStep(steps.error);
    }
  }, [error, steps.error]);

  const hidePopup = () => {
    dispatch(hidePopupWindow(popupName));
  };

  const moveToYouthCamp = () => {
    hidePopup();
    dispatch(setYouthCampScoutStatus(null));
    history.push('/youth-camp');
  };

  const moveToComplete = () => {
    if (!NSF) {
      dispatch(youthCampRegisterRequest(scoutVal));
      changeStep(steps.complete);
    }
  };

  const closePopup = () => {
    if (shouldRedirect) {
      moveToYouthCamp();
    } else {
      hidePopup();
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross
          onClick={() => {
            closePopup();
          }}
        />
        <StyledPopUpContainer>
          {isPopupLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {step === steps.confirm && !NSF && (
                <>
                  <StyledHeader>Confirm Scouts</StyledHeader>
                  <StyledParagraph>
                    Sending <b>{scoutVal} </b> scouts will cost{' '}
                    <b>${numberWithCommas(scoutVal * 300000)}</b>
                  </StyledParagraph>
                  <StyledParagraph> Would you like to proceed?</StyledParagraph>
                </>
              )}
              {step === steps.error && !shouldRedirect && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              {step === steps.confirm && NSF && (
                <>
                  <StyledHeader>Not enough funds.</StyledHeader>
                  <StyledParagraph>
                    We don't have sufficient funds to scout.
                  </StyledParagraph>
                </>
              )}
              {shouldRedirect && step !== steps.error && (
                <>
                  <StyledHeader>Welcome to Wagner’s youth camp!</StyledHeader>
                  <StyledParagraph />
                  <StyledButtonContainer>
                    <StyledButton primary onClick={() => moveToYouthCamp()}>
                      Go to youth camp!
                    </StyledButton>
                  </StyledButtonContainer>
                </>
              )}
              {shouldRedirect && step === steps.error && (
                <>
                  <StyledHeader>{error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {step === steps.confirm && (
                  <>
                    <StyledButton onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton
                      primary
                      onClick={() => {
                        moveToComplete();
                      }}
                    >
                      Confirm
                    </StyledButton>
                  </>
                )}
                {step === steps.complete ||
                  (shouldRedirect &&
                    (step === steps.error || step !== steps.error) && (
                      <StyledButton primary onClick={() => moveToYouthCamp()}>
                        Go to youth camp!
                      </StyledButton>
                    ))}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(YouthCampScoutPopup);
