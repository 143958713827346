import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import usePortal from 'react-useportal';

import {
  StyledHeader,
  StyledLeftHeaderSection,
  StyledRightHeaderSection,
  StyledCardWrapper,
  StyledTOLLogo,
  StyledTOLMobileLogo,
  StyledBackButton,
  StyledTitle,
  StyledSubheader,
  StyledLeftWhiteArrow,
  StyledWrapper,
  StyledMobileSubheader,
  StyledMobileBackButton,
} from './styled';
import { StyledLoader } from '../styled';

import TeamCard from '../../components/TeamCard';
import Matchday from '../../components/Matchday';
import {
  ReadMorePopup,
  NotificationPopup,
  TermsPopup,
  DiscordLoginPopup,
  NonDiscordPopup,
} from '../../components/Popup';

import {
  sendLeagueCode,
  setTeamResponseCode,
  getUniverses,
} from '../../redux/actions/SignUp';
import {
  addNotificationPopupRequest,
  showPopupWindow,
} from '../../redux/actions/Popups';

import {
  getSignUpData,
  getSignUpTeamById,
  isAnyTeamAvailable,
  getLoadingUniversesData,
} from '../../redux/selector/SignUp';
import { getPopupByName } from '../../redux/selector/Popups';
import { getMyCurrentTeam, isAuth } from '../../redux/selector/User';
import { POPUPS } from '../../service/constants';

const SelectTeam = () => {
  const [selectedTeamId, setTeamId] = React.useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { READ_MORE_POPUP, NO_FREE_TEAM_POPUP, NON_DISCORD_POPUP } = POPUPS;
  const { leagueCodeParam } = useParams();
  const noFreePopupData = useSelector(getPopupByName(NO_FREE_TEAM_POPUP));
  const readMorePopupData = useSelector(getPopupByName(READ_MORE_POPUP));
  const noDiscordPopup = useSelector(getPopupByName(NON_DISCORD_POPUP));
  const isAuthenticated = useSelector(isAuth);
  const {
    isLoading,
    chooseTeamResponseCode,
    selectTeamList,
    error,
    leagueCode,
  } = useSelector(getSignUpData);
  const isAnyTeamToChoose = useSelector(isAnyTeamAvailable());

  const myTeam = useSelector(getMyCurrentTeam);
  const getTeamData = useSelector(getSignUpTeamById(selectedTeamId));
  const { universesList, universesListLoading } = useSelector(
    getLoadingUniversesData
  );
  const env = runtimeEnv();
  const { REACT_APP_AUTH_LINK } = env;
  useEffect(() => {
    if (!isEmpty(selectTeamList) && !isAnyTeamToChoose) {
      dispatch(
        addNotificationPopupRequest({
          name: NO_FREE_TEAM_POPUP,
          message:
            'All teams in this league are currently owned. Try again later.',
        })
      );
    }
  }, [selectTeamList, isAnyTeamToChoose, dispatch, NO_FREE_TEAM_POPUP]);

  useEffect(() => {
    const code = leagueCode || leagueCodeParam;

    if (isEmpty(selectTeamList) && code) {
      dispatch(sendLeagueCode(code));
    }

    if (!code || error) {
      history.push('/signup');
    }
  }, [history, dispatch, error, selectTeamList, leagueCode, leagueCodeParam]);
  const setTeamIdCb = (id) => {
    setTeamId(id);
  };
  React.useEffect(() => {
    switch (chooseTeamResponseCode) {
      case 405:
      case 200:
        if (!myTeam) {
          dispatch(
            showPopupWindow(
              POPUPS.DISCORD_LOGIN_POPUP,
              POPUPS.DISCORD_LOGIN_POPUP
            )
          );
        }
        break;
      case 203:
        dispatch(
          showPopupWindow(
            POPUPS.DISCORD_LOGIN_POPUP,
            POPUPS.DISCORD_LOGIN_POPUP
          )
        );
        break;
      default:
    }
    return () => {
      dispatch(setTeamResponseCode(null));
    };
  }, [
    chooseTeamResponseCode,
    dispatch,
    history,
    REACT_APP_AUTH_LINK,
    myTeam,
    getTeamData,
  ]);
  React.useEffect(() => {
    if (isEmpty(universesList)) {
      dispatch(getUniverses());
    }
  }, [dispatch, universesList]);
  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  const termsPopupName = 'termsPopup';
  const termsPopupData = useSelector(getPopupByName(termsPopupName));

  const universeName =
    !isEmpty(universesList) &&
    leagueCodeParam &&
    universesList.find((universe) =>
      universe.leagues.some((league) => league.code === leagueCodeParam)
    ).name;
  const discordLoginPopupData = useSelector(
    getPopupByName(POPUPS.DISCORD_LOGIN_POPUP)
  );
  const leagueName =
    !isEmpty(universesList) &&
    leagueCodeParam &&
    universesList
      .find((universe) =>
        universe.leagues.some((league) => league.code === leagueCodeParam)
      )
      .leagues.find((league) => league.code === leagueCodeParam).name;

  const recommendedTeam = selectTeamList
    .filter((team) => team.free)
    .reduce(
      (prev, current) => (prev.pointsSum > current.pointsSum ? prev : current),
      []
    );

  const sortedTeamsList = [
    recommendedTeam,
    ...selectTeamList.filter((team) => team._id !== recommendedTeam._id),
  ];

  const teamName = selectTeamList.filter((team) => team._id === selectedTeamId);


  const selectedUniverse = universesList.filter(universe => universe.leagues.some(league => league.code === leagueCodeParam))[0]?.name
  const redirectToPage = () => {
    history.push('/signup', { universe: selectedUniverse });
  };
  return (
    <>
      {!isEmpty(noDiscordPopup) && (
        <Portal>
          <NonDiscordPopup
            popupName={POPUPS.NON_DISCORD_POPUP}
            selectedTeamId={selectedTeamId}
            teamName={teamName[0].name}
          />
        </Portal>
      )}
      {discordLoginPopupData && (
        <Portal>
          <DiscordLoginPopup />
        </Portal>
      )}
      {!isEmpty(termsPopupData) && selectedTeamId && (
        <Portal>
          <TermsPopup
            universeName={universeName}
            id={selectedTeamId}
            sendTeamIdCallback={(id) => {
              setTeamIdCb(id);
            }}
          />
        </Portal>
      )}
      {!isEmpty(readMorePopupData) && (
        <Portal>
          <ReadMorePopup
            popupName={READ_MORE_POPUP}
            id={readMorePopupData.id}
          />
        </Portal>
      )}
      {!isLoading && !isEmpty(noFreePopupData) && (
        <Portal>
          <NotificationPopup
            popupName={NO_FREE_TEAM_POPUP}
            closeCallback={() => { }}
          />
        </Portal>
      )}
      <StyledHeader>
        {' '}
        <StyledLeftHeaderSection>
          {isMobile ? <StyledTOLMobileLogo /> : <StyledTOLLogo />}
        </StyledLeftHeaderSection>
        <StyledRightHeaderSection>
          <Matchday isHeaderBased />
        </StyledRightHeaderSection>
      </StyledHeader>
      <StyledWrapper>
        {isLoading || universesListLoading ? (
          <StyledLoader type="Bars" color="#00BFFF" height={100} width={100} />
        ) : (
          <>
            {isMobile ? (
              <>
                <StyledMobileSubheader>
                  <StyledMobileBackButton onClick={redirectToPage}>
                    <StyledLeftWhiteArrow />
                    Select league
                  </StyledMobileBackButton>
                </StyledMobileSubheader>
                <StyledTitle>{leagueName}</StyledTitle>
              </>
            ) : (
              <StyledSubheader>
                <StyledBackButton onClick={redirectToPage}>
                  <StyledLeftWhiteArrow />
                  Select league
                </StyledBackButton>
                <StyledTitle>{leagueName}</StyledTitle>
              </StyledSubheader>
            )}
            <StyledCardWrapper>
              {sortedTeamsList &&
                sortedTeamsList.map((item) => (
                  <TeamCard
                    isTeamRecommended={recommendedTeam._id === item._id}
                    isTeamOwner={!isEmpty(myTeam) && isAuthenticated}
                    sendTeamIdCallback={(id) => {
                      setTeamIdCb(id);
                    }}
                    key={item._id}
                    {...item}
                  />
                ))}
            </StyledCardWrapper>
          </>
        )}
      </StyledWrapper>
    </>
  );
};

export default SelectTeam;
