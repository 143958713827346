import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { StyledButton } from '../../../globalStyled';
import { setPlayerNickname } from '../../../redux/actions/Team';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
  StyledInputField,
} from '../styled';

import { hidePopupWindow } from '../../../redux/actions/Popups';

import { getPopupByName } from '../../../redux/selector/Popups';

const NicknamePopup = ({ playerName, popupName, playerId }) => {
  const DEFAULT_STEP = 'default';
  const CONFIRMATION_STEP = 'confirmation';
  const COMPLETE_STEP = 'complete';

  const [step, showStep] = useState(DEFAULT_STEP);

  const [nickname, setNickname] = useState('');
  const { error, isLoading } = useSelector(getPopupByName(popupName));
  const dispatch = useDispatch();

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  const onInputChange = (e) => {
    setNickname(e.target.value);
  };

  const confirmNickname = () => {
    showStep(CONFIRMATION_STEP);
  };
  const approveNickname = () => {
    showStep(COMPLETE_STEP);
    dispatch(setPlayerNickname(playerId, nickname));
  };

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer noOverflow>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {step === DEFAULT_STEP && !error && (
                <>
                  <StyledHeader>Player nickname</StyledHeader>
                  <StyledParagraph>
                    What nickname should we give to{' '}
                    <strong>{playerName}</strong>?
                  </StyledParagraph>
                  <StyledInputField
                    ref={inputRef}
                    value={nickname}
                    placeholder="Type player nickname"
                    onChange={onInputChange}
                  />
                </>
              )}
              {step === CONFIRMATION_STEP && !error && (
                <>
                  <StyledHeader>Nickname confirmation</StyledHeader>

                  <StyledParagraph>
                    Are you sure you want to use <strong>{nickname}</strong>?
                    Nicknames are forever and will be set until player retires
                  </StyledParagraph>
                </>
              )}
              {step === COMPLETE_STEP && !error && (
                <>
                  <StyledHeader>Thank you.</StyledHeader>
                  <StyledParagraph>Nickname has been set!</StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {step === DEFAULT_STEP && !error && (
                  <>
                    <StyledButton basic onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton
                      primary
                      disabled={isEmpty(nickname)}
                      onClick={() => confirmNickname()}
                    >
                      Next
                    </StyledButton>
                  </>
                )}
                {step === CONFIRMATION_STEP && !error && (
                  <>
                    <StyledButton basic onClick={() => showStep(DEFAULT_STEP)}>
                      Back
                    </StyledButton>
                    <StyledButton danger onClick={() => approveNickname()}>
                      Yes, give nickname
                    </StyledButton>
                  </>
                )}
                {error && (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};
NicknamePopup.propTypes = {
  playerId: PropTypes.string.isRequired,
  playerName: PropTypes.string.isRequired,
  popupName: PropTypes.string.isRequired,
};
export default React.memo(NicknamePopup);
