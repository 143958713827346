import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';

import { hackRequest } from '../../../redux/actions/Attack';

import { getPopupByName } from '../../../redux/selector/Popups';

const HackPopup = ({ cash, popupName, penaltiSize, awayTeam }) => {
  const [defaultStep, showDefaultStep] = useState(true);
  const NSF = cash - penaltiSize < 0;

  const { error, isLoading } = useSelector(getPopupByName(popupName));

  useEffect(() => {
    showDefaultStep(true);
  }, []);
  const dispatch = useDispatch();
  const sendHack = () => {
    showDefaultStep(false);
    if (!NSF) {
      dispatch(hackRequest());
    }
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {defaultStep && !NSF && (
                <>
                  <StyledHeader>Hack Team</StyledHeader>
                  <StyledParagraph>
                    There is a cost of $100K to hack {awayTeam}... and no
                    guarantee the hack will work. If we get busted by the
                    league, we’ll get a $500K penalty. If it works, {awayTeam}{' '}
                    will be impaired in our next match.
                  </StyledParagraph>
                  <StyledParagraph> Do you want to proceed?</StyledParagraph>
                </>
              )}
              {NSF && defaultStep && (
                <>
                  <StyledHeader> Not enough money</StyledHeader>
                  <StyledParagraph>
                    You don't have enough money to perform this operation
                  </StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {defaultStep ? (
                  <>
                    <StyledButton onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton primary onClick={sendHack}>
                      Proceed
                    </StyledButton>
                  </>
                ) : (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(HackPopup);
