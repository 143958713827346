import styled, { css } from 'styled-components';
import Loader from 'react-loader-spinner';
import { NavLink } from 'react-router-dom';
import { StyledButton } from '../../globalStyled';

export const StyledTextNavLink = styled(NavLink)`
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  margin: 0 5px;
  text-align: center;
`;
export const StyledMailLink = styled.a`
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  margin: 0 5px;
  text-align: center;
`;
export const StyledExternalLink = styled.span`
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  margin: 0 5px;
  text-align: center;
  ${({ white }) => {
    if (white) {
      return css`
        color: #fff;
        margin: 0 10px;
      `;
    }
  }}
`;
export const StyledPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  height: 100vh;
`;
export const StyledDarkSection = styled.section`
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #0a0b0d;

  height: auto;
  padding-bottom: 100px;
  @media only screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  > img {
    position: absolute;
    max-height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    width: auto;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
    @media only screen and (max-width: 991px) {
      opacity: 0.4;
      max-width: 500px;
      width: 100%;
      left: 0;
    }
  }
`;
export const StyledHeader = styled.header`
  z-index: 2;
  display: flex;
  height: 100px;
  width: 100%;
  background-color: transparent;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  @media only screen and (max-width: 991px) {
    padding: 20px;
    height: 55px;
    background-color: #000;
  }
  img {
    width: 180px;
    margin-right: 10px;
    @media only screen and (max-width: 425px) {
      width: 150px;
    }
  }
`;
export const StyledSection = styled.section`
  width: calc(100% - 380px);
  height: 100%;
  text-align: center;
  /*   max-width: 525px;
  */
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  margin: 0 60px;
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin: 0px;
    padding: 0 25px;
  }
`;

export const StyledLeftHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  img {
    width: 180px;
    margin-right: 10px;
    @media only screen and (max-width: 425px) {
      width: 150px;
    }
  }
`;

export const StyledRightHeaderSection = styled.div`
  font-family: Gilroy;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  p {
    font-size: 17px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: Gilroy;
  font-size: 60px;
  font-weight: 600;
  text-transform: none;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 0.92px;
  color: #ffffff;
  margin-bottom: 40px;
  b {
    font-weight: 800;
  }
  @media only screen and (max-width: 991px) {
    font-size: 42px;
    margin-top: 25px;
    font-weight: 800;
  }
`;

export const StyledSubtitle = styled.h1`
  font-family: 'Gilroy', sans-serif;
  font-size: 20px;
  /*   max-width: 400px; */
  line-height: 33px;
  text-transform: none;
  color: #fff;
  font-weight: 500;
  b {
    font-weight: 700;
  }
  @media only screen and (max-width: 991px) {
    font-size: 14px;
    margin-top: 20px;
    width: 100%;
  }
`;
export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ loadButtonsSection }) => {
    if (loadButtonsSection) {
      return css`
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        width: 400px;
        button {
          width: auto;
          min-width: 135px;
        }
      `;
    }
  }}
  button {
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }
`;
export const StyledLandingButton = styled(StyledButton)`
  width: 100%;
  min-width: 255px;
  height: 40px;
  font-size: 14px;
  background-color: #919191;
  border-radius: 5px;
  border: 1px solid #919191;
  color: #0a0b0d;
  padding: 12px 10px;
  svg {
    margin-right: 5px;
  }
  ${({ rounded }) => {
    if (rounded) {
      return css`
        padding: 12px 45px;
        border-radius: 20px;
      `;
    }
  }}
  ${({ small }) => {
    if (small) {
      return css`
        max-width: 170px;
        min-width: 120px;
        font-size: 16px;
      `;
    }
  }}
  ${({ primary }) => {
    if (primary) {
      return css`
        background-color: #fdca40;
        border: 1px solid #fdca40;
        color: #000;
      `;
    }
  }}
  ${({ dark }) => {
    if (dark) {
      return css`
        background-color: #000;
        border: 1px solid #fff;
        color: #fff;
      `;
    }
  }}
  ${({ brand }) => {
    if (brand) {
      return css`
        background-color: #737df8;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
        color: #fff;
        width: auto;
      `;
    }
  }}
  @media only screen and (max-width: 991px) {
    font-size: 14px;
    height: 35px;

    min-width: 105px;
  }
  &:disabled {
    background-color: #ebebeb;
    color: #989898;
    border: 1px solid #ebebeb;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
  }
`;

export const StyledDetailsContainer = styled.section`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  background-color: #fff;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 90px;
  @media only screen and (max-width: 991px) {
    display: flex;
    justify-items: center;
    flex-direction: column;
    width: 100%;
  }

  ${({ grey }) => {
    if (grey) {
      return css`
        margin-top: -70px;
        padding: 150px 0;

        background-color: #f8f8f8;
        @media only screen and (max-width: 991px) {
          flex-direction: column-reverse;
          margin-top: 0;
          padding: 50px 0 0 0;
        }
      `;
    }
  }}
  ${({ small }) => {
    if (small) {
      return css`
        padding: 50px 0;
      `;
    }
  }};
`;

export const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const StyledContainerLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: white;
`;
export const StyledImgContainer = styled.div`
  width: 65%;
  position: relative;
  text-align: left;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  ${({ center }) => {
    if (center) {
      return css`
        text-align: center;
        @media only screen and (max-width: 991px) {
          text-align: center;
        }
      `;
    }
  }}
  ${({ right }) => {
    if (right) {
      return css`
        text-align: right;
        @media only screen and (max-width: 991px) {
          text-align: center;
        }
      `;
    }
  }}
  ${({ halfSize }) => {
    if (halfSize) {
      return css`
        width: 50%;
        @media only screen and (max-width: 991px) {
          width: 100%;
          text-align: center;
        }
      `;
    }
  }}
`;
export const StyledImage = styled.img`
  width: 100%;
  max-width: 600px;
  box-shadow: ${({ noShadow }) =>
    noShadow ? 'none' : '0 0 25px 0 rgba(0, 0, 0, 0.3)'};
  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }
`;
export const StyledDescriptionContainer = styled.div`
  width: 35%;
  padding: 50px;
  margin-left: 50px;
  @media only screen and (max-width: 991px) {
    padding: 50px 15px;
    text-align: center;
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  ${({ halfSize }) => {
    if (halfSize) {
      return css`
        width: 50%;
        @media only screen and (max-width: 991px) {
          width: 100%;
        }
      `;
    }
  }}
  ${({ centered }) => {
    if (centered) {
      return css`
        margin: 0 40px;
        text-align: center;
        @media only screen and (max-width: 991px) {
          margin: 0;
          padding: 20px 15px;
        }
      `;
    }
  }}
`;
export const StyledDescriptionHeader = styled.h2`
  font-size: 40px;
  @media only screen and (max-width: 991px) {
    line-height: 40px;
    font-size: 30px;
  }

  ${({ italic }) => {
    if (italic) {
      return css`
        font-style: italic;
      `;
    }
  }}
`;
export const StyledDescriptionSubheader = styled.p`
  margin: 15px 0;
  line-height: 24px;
`;
export const StyledAuthor = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-weight: 100;
  line-height: 50px;
  font-size: 30px;
  @media only screen and (max-width: 991px) {
    font-size: 25px;
  }
`;

export const StyledCenteredContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 991px) {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
`;

export const StyledFeatured = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-weight: 300;
  font-style: italic;
  background-color: #000;
  height: 100%;
  padding: 30px 15px;
  position: absolute;
  right: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  img {
    max-width: 100px;
    margin: 10px 0;
    @media only screen and (max-width: 991px) {
      max-width: 80px;
    }
  }
  @media only screen and (max-width: 991px) {
    position: relative;
    width: 100%;
    margin-top: 35px;
  }
`;

export const StyledSpan = styled.span`
  font-size: 10px;
  ${({ small }) => {
    if (small) {
      return css`
        font-size: 6px;
      `;
    }
  }}
`;

export const StyledLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledFeaturedImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 991px) {
    flex-direction: row;
    margin-top: 20px;
  }
`;
