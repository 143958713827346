import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledCross,
  StyledPopUpContainer,
  StyledParagraph,
  StyledHeader,
  StyledLoader,
} from '../styled';

import PlayerStatCard from '../../PlayerStatCard';
import {
  StyledButtonContainer,
  StyledPopupButton,
  StyledPopUpBlockNoPadding,
} from './styled';
import {
  hidePopupWindow,
  showPopupWindow,
} from '../../../redux/actions/Popups';
import { getPopupByName } from '../../../redux/selector/Popups';
import { getAcademyList } from '../../../redux/selector/Team';
import { POPUPS } from '../../../service/constants';

const PlayerInfoPopup = ({ popupName, playerInfoData }) => {
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(getPopupByName(popupName));
  const academyList = useSelector(getAcademyList);
  const { FREE_AGENT_POPUP } = POPUPS;

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };
  const sendFreeAgent = (id) => {
    dispatch(hidePopupWindow(popupName));
    dispatch(showPopupWindow(id, FREE_AGENT_POPUP, false));
  };
  return (
    <StyledPopUp>
      <StyledPopUpBlockNoPadding>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#00BFFF"
              height={100}
              width={100}
            />
          ) : (
            <>
              {error ? (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>

                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                </>
              ) : (
                <>
                  <PlayerStatCard
                    academyList={academyList}
                    playerWhoWasSold={playerInfoData}
                  />
                  <>
                    {isEmpty(playerInfoData.team) &&
                      !error &&
                      playerInfoData.life_stage !== 3 &&
                      !academyList.includes(playerInfoData._id) && (
                        <StyledButtonContainer>
                          <StyledPopupButton
                            primary
                            onClick={() => sendFreeAgent(playerInfoData._id)}
                          >
                            Sign free agent
                          </StyledPopupButton>
                        </StyledButtonContainer>
                      )}
                  </>
                </>
              )}
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlockNoPadding>
    </StyledPopUp>
  );
};

export default React.memo(PlayerInfoPopup);
