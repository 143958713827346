import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StyledPopUp, StyledCross, StyledLoader } from '../styled';

import PlayerStatCard from '../../PlayerStatCard';
import {
  StyledPopUpBlockNoPadding,
  StyledStaticPopUpContainer,
  StyleLoaderContainer,
} from './styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { getPopupByName } from '../../../redux/selector/Popups';

const PlayerInfoPopupView = ({ popupName, data, isMobile = false }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(getPopupByName(popupName));

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlockNoPadding>
        <StyledCross onClick={() => hidePopup()} />
        <StyledStaticPopUpContainer>
          {isLoading ? (
            <StyleLoaderContainer>
              <StyledLoader
                type="Bars"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </StyleLoaderContainer>
          ) : (
            <PlayerStatCard playerWhoWasSold={data} isMobile={isMobile} />
          )}
        </StyledStaticPopUpContainer>
      </StyledPopUpBlockNoPadding>
    </StyledPopUp>
  );
};

export default React.memo(PlayerInfoPopupView);
