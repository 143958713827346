import React from 'react';
import { useSelector } from 'react-redux';
import { StyledTableLoader } from '../../../globalStyled';
import { GroupTeamDesktop } from '../../GroupTeam';
import DomesticCupWinner from '../../DomesticCupWinner';
import {
  StyledSemifinalGroup,
  StyledRound,
  StyledGroup,
  StyledBorder,
  StyledSection,
  StyledRoundContainer,
  StyledSemifinalSection,
  StyledFinalSection,
  StyledFinalGroup,
} from './styled';
import {
  firstRoundFirstSetTop,
  firstRoundFirstSetBottom,
  firstRoundSecondSetTop,
  firstRoundSecondSetBottom,
  secondRoundFirstSet,
  secondRoundSecondSet,
  finalRound,
  domesticTableLoading,
} from '../../../redux/selector/DomesticCup';
import { getGroupWinner } from '../../../service/utils';

const CupGroupTable = () => {
  const isDomesticTableLoading = useSelector(domesticTableLoading);
  const {
    homeTeam: firstRoundFirstSetTopHome,
    awayTeam: firstRoundFirstSetTopAway,
  } = useSelector(firstRoundFirstSetTop);
  const {
    homeTeam: firstRoundFirstSetBottomHome,
    awayTeam: firstRoundFirstSetBottomAway,
  } = useSelector(firstRoundFirstSetBottom);

  const {
    homeTeam: firstRoundSecondSetTopHome,
    awayTeam: firstRoundSecondSetTopAway,
  } = useSelector(firstRoundSecondSetTop);
  const {
    homeTeam: firstRoundSecondSetBottomHome,
    awayTeam: firstRoundSecondSetBottomAway,
  } = useSelector(firstRoundSecondSetBottom);

  const {
    homeTeam: secondRoundFirstSetHome,
    awayTeam: secondRoundFirstSetAway,
  } = useSelector(secondRoundFirstSet);
  const {
    homeTeam: secondRoundSecondSetHome,
    awayTeam: secondRoundSecondSetAway,
  } = useSelector(secondRoundSecondSet);

  const { homeTeam: finalRoundFirstSetHome, awayTeam: finalRoundFirstSetAway } =
    useSelector(finalRound);

  const winner = getGroupWinner(finalRoundFirstSetHome, finalRoundFirstSetAway);

  return (
    <>
      {isDomesticTableLoading ? (
        <StyledTableLoader
          type="Bars"
          color="#00BFFF"
          height={100}
          width={100}
        />
      ) : (
        <>
          <StyledRound side="left">
            <StyledRoundContainer>
              <StyledSection>
                <StyledGroup>
                  <GroupTeamDesktop teamData={firstRoundFirstSetTopHome} />
                  <GroupTeamDesktop teamData={firstRoundFirstSetTopAway} />
                </StyledGroup>
                <StyledBorder />
              </StyledSection>
              <StyledSection>
                <StyledGroup>
                  <GroupTeamDesktop teamData={firstRoundFirstSetBottomHome} />
                  <GroupTeamDesktop teamData={firstRoundFirstSetBottomAway} />
                </StyledGroup>
                <StyledBorder />
              </StyledSection>
            </StyledRoundContainer>
            <StyledSemifinalSection>
              <StyledSemifinalGroup>
                <GroupTeamDesktop
                  teamData={secondRoundFirstSetHome}
                  shortVersion
                />
                <GroupTeamDesktop
                  teamData={secondRoundFirstSetAway}
                  shortVersion
                />
              </StyledSemifinalGroup>
              <StyledBorder large />
            </StyledSemifinalSection>
            <StyledFinalSection>
              <StyledFinalGroup>
                <GroupTeamDesktop
                  teamData={finalRoundFirstSetHome}
                  shortVersion
                />
              </StyledFinalGroup>
            </StyledFinalSection>
          </StyledRound>
          <DomesticCupWinner logo={winner.symbol} />
          <StyledRound side="right">
            <StyledRoundContainer>
              <StyledSection side="right">
                <StyledGroup>
                  <GroupTeamDesktop
                    teamData={firstRoundSecondSetTopHome}
                    side="right"
                  />
                  <GroupTeamDesktop
                    teamData={firstRoundSecondSetTopAway}
                    side="right"
                  />
                </StyledGroup>
                <StyledBorder side="right" />
              </StyledSection>
              <StyledSection side="right">
                <StyledGroup>
                  <GroupTeamDesktop
                    teamData={firstRoundSecondSetBottomHome}
                    side="right"
                  />
                  <GroupTeamDesktop
                    teamData={firstRoundSecondSetBottomAway}
                    side="right"
                  />
                </StyledGroup>
                <StyledBorder side="right" />
              </StyledSection>
            </StyledRoundContainer>
            <StyledSemifinalSection side="right">
              <StyledSemifinalGroup>
                <GroupTeamDesktop
                  teamData={secondRoundSecondSetHome}
                  side="right"
                  shortVersion
                />
                <GroupTeamDesktop
                  teamData={secondRoundSecondSetAway}
                  side="right"
                  shortVersion
                />
              </StyledSemifinalGroup>
              <StyledBorder large side="right" />
            </StyledSemifinalSection>
            <StyledFinalSection side="right">
              <StyledFinalGroup side="right">
                <GroupTeamDesktop
                  teamData={finalRoundFirstSetAway}
                  side="right"
                  shortVersion
                />
              </StyledFinalGroup>
            </StyledFinalSection>
          </StyledRound>
        </>
      )}
    </>
  );
};

export default CupGroupTable;
