import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import {
  StyledPageWrapper,
  StyledHeader,
  StyledSection,
  StyledLeftHeaderSection,
  StyledRightHeaderSection,
  StyledDarkSection,
  StyledTitle,
  StyledSubtitle,
  StyledLandingButton,
  StyledButtonsContainer,
  StyledDetailsContainer,
  StyledContainerLoader,
  StyledLoader,
  StyledDescriptionContainer,
  StyledDescriptionHeader,
  StyledDescriptionSubheader,
  StyledImgContainer,
  StyledImage,
  StyledTextNavLink,
  StyledMailLink,
  StyledExternalLink,
  StyledCenteredContainer,
  StyledFeatured,
  StyledSpan,
  StyledLinksWrapper,
  StyledFeaturedImageContainer,
} from './styled';
import { ReactComponent as LOGO } from '../../assets/img/tol-logo.svg';
import { ReactComponent as LOGOMobile } from '../../assets/img/tol-logo-mobile.svg';
import Athletic from '../../assets/img/featured/athletic.png';
import GMGames from '../../assets/img/featured/gmgames.png';
import Guardian from '../../assets/img/featured/guardian.png';
import TechCrunch from '../../assets/img/featured/techcrunch.png';
import TheSun from '../../assets/img/featured/thesun.png';
import LandingBig from '../../assets/img/landing-big.png';
import LandingSmall from '../../assets/img/landing-small.png';
import SimpleGameplay from '../../assets/img/simple-gameplay.png';
import SimpleGameplayMobile from '../../assets/img/simple-gameplay-mobile.png';
import MultiDeviceScreen from '../../assets/img/multi-device-screen.png';
import DiscordScreen from '../../assets/img/discord-screen.png';
import DailyMatch from '../../assets/img/daily-match.png';
import PatreonSubscription from '../../assets/img/patreon-subscription.png';
import { testAuth } from '../../redux/actions/User';
import { isAuth, showLoader } from '../../redux/selector/User';
import { campaignParams } from '../../service/mixpanel_utm';
import MetaPixel from '../../components/MetaPixel';

const StartPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(isAuth);
  const isLoading = useSelector(showLoader);
  const env = runtimeEnv();
  const { REACT_APP_AUTH_LINK } = env;
  React.useEffect(() => {
    if (isAuthenticated === null) {
      dispatch(testAuth());
    }
    if (isAuthenticated) {
      history.push('/home');
    }
  }, [isAuthenticated, dispatch, history]);

  React.useEffect(() => {
    campaignParams();
  }, []);

  const clickLogin = () => {
    mixpanel.track('Login clicked');
    if (isAuthenticated) {
      history.push('/home');
    } else {
      window.location.href = REACT_APP_AUTH_LINK;
    }
  };

  const clickRegister = () => {
    mixpanel.track('Register button clicked');
    localStorage.setItem('isFromRegister', true);
    history.push('/signup');
  };

  const clickBlog = () => {
    mixpanel.track('Blog clicked');
    history.push('/blog');
  };

  const goToUrl = (url) => window.open(url, '_blank');

  return (
    <>
      {isLoading || isAuthenticated === null ? (
        <StyledContainerLoader>
          <StyledLoader type="Bars" color="#00BFFF" height={100} width={100} />
        </StyledContainerLoader>
      ) : (
        <StyledPageWrapper>
          <StyledDarkSection>
            <img
              src={isMobile ? LandingSmall : LandingBig}
              alt="Online Soccer Game"
            />
            <StyledHeader>
              <StyledLeftHeaderSection>
                {isMobile ? <LOGOMobile /> : <LOGO />}
              </StyledLeftHeaderSection>
            </StyledHeader>

            <StyledSection>
              <StyledTitle>
                <b>Casual</b> football manager game for the football{' '}
                <b>enthusiast</b>
              </StyledTitle>
              <StyledSubtitle>
                The Open League is a social, easy-to-play, football manager game
                fully integrated with Discord for lovers of the beautiful sport
                who don’t have all day to mess around
              </StyledSubtitle>
              <StyledRightHeaderSection>
                <StyledButtonsContainer>
                  <StyledLandingButton
                    id="register-link"
                    primary
                    rounded
                    small
                    onClick={clickRegister}
                  >
                    <MetaPixel />
                    Register
                  </StyledLandingButton>
                  <StyledLinksWrapper>
                    <StyledExternalLink
                      white
                      onClick={() => {
                        clickLogin();
                      }}
                    >
                      Login
                    </StyledExternalLink>
                    |
                    <StyledExternalLink
                      white
                      onClick={() => {
                        clickBlog();
                      }}
                    >
                      Blog
                    </StyledExternalLink>
                  </StyledLinksWrapper>
                </StyledButtonsContainer>
              </StyledRightHeaderSection>
            </StyledSection>
            <StyledFeatured>
              {isMobile && (
                <StyledSpan small>
                  Featured in at least one of these fine publications
                </StyledSpan>
              )}
              {!isMobile && (
                <StyledSpan>
                  Featured in at least one of these fine publications*
                </StyledSpan>
              )}
              <StyledFeaturedImageContainer>
                <StyledImage src={Athletic} alt="Athletic" />
                <StyledImage src={GMGames} alt="GMGames" />
                <StyledImage src={Guardian} alt="Guardian" />
                <StyledImage src={TechCrunch} alt="TechCrunch" />
                <StyledImage src={TheSun} alt="TheSun" />
              </StyledFeaturedImageContainer>
              {!isMobile && (
                <StyledSpan small>
                  *Yes, this is totally deceptive - we are just testing how
                  effective adding these brands is at making you Register for
                  The Open League
                </StyledSpan>
              )}
            </StyledFeatured>
          </StyledDarkSection>
          <StyledDetailsContainer>
            <StyledImgContainer center>
              <StyledImage
                src={isMobile ? SimpleGameplayMobile : SimpleGameplay}
                alt="Football Manager Game"
              />
            </StyledImgContainer>
            <StyledDescriptionContainer>
              <StyledDescriptionHeader>
                Simple gameplay and interface
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                You don’t need to watch 30 tutorials to thrive in The Open
                League. Simple controls, simple interface. The Open League was
                built for football fans with limited time on their hands. To be
                clear: there is plenty of fun to be had on TOL but if you are
                looking for a game that feels like a full-time job, look
                elsewhere.
              </StyledDescriptionSubheader>
              <StyledLandingButton dark small onClick={clickRegister}>
                Join now
              </StyledLandingButton>
            </StyledDescriptionContainer>
          </StyledDetailsContainer>
          <StyledDetailsContainer grey>
            <StyledDescriptionContainer>
              <StyledDescriptionHeader>
                Daily football matches
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                League matches are simulated daily with text highlights
                streaming real-time on Discord. A season lasts three weeks. Top
                teams are promoted, bottom ones are demoted. During the
                off-season, teams register for youth camp where they scout and
                sign youth talent.
              </StyledDescriptionSubheader>
              <StyledLandingButton dark small onClick={clickRegister}>
                Join now
              </StyledLandingButton>
            </StyledDescriptionContainer>
            <StyledImgContainer center>
              <StyledImage small src={DailyMatch} alt="Daily matches" />
            </StyledImgContainer>
          </StyledDetailsContainer>
          <StyledDetailsContainer>
            <StyledImgContainer halfSize center>
              <StyledImage noShadow src={DiscordScreen} alt="Discord" />
            </StyledImgContainer>
            <StyledDescriptionContainer halfSize>
              <StyledDescriptionHeader>
                First football manager integrated with Discord
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                We built The Open League to be the friendliest football manager
                game ever and we couldn’t do that without integrating it fully
                with Discord. Managers follow daily matches on the platform,
                they negotiate transfers with other managers, and just come to
                chill and kill time. We’ve also built bots who can answer your
                questions and help you navigate TOL.
              </StyledDescriptionSubheader>
              <StyledLandingButton brand onClick={clickRegister}>
                Join our server now
              </StyledLandingButton>
            </StyledDescriptionContainer>
          </StyledDetailsContainer>
          <StyledDetailsContainer grey>
            <StyledDescriptionContainer>
              <StyledDescriptionHeader>
                Free to play forever. No pay-to-win.
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                We all love free stuff - The Open League is free for everyone
                forever. We offer pro subsciptions to our most dedicated users
                however these features do not give managers an edge in the game.
                We hate pay-to-win and lootbox scams.
              </StyledDescriptionSubheader>
              <StyledLandingButton primary small onClick={clickRegister}>
                Join now
              </StyledLandingButton>
            </StyledDescriptionContainer>
            <StyledImgContainer center>
              <StyledImage small src={PatreonSubscription} alt="Patreon" />
            </StyledImgContainer>
          </StyledDetailsContainer>
          <StyledDetailsContainer>
            <StyledImgContainer halfSize right>
              <StyledImage
                noShadow
                src={MultiDeviceScreen}
                alt="Free To Play"
              />
            </StyledImgContainer>
            <StyledDescriptionContainer halfSize>
              <StyledDescriptionHeader>
                Play online anytime,
                <br /> anywhere.
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                You can manage your club from virtually any online device with a
                browser. We are also working on new platforms (Steam, Desktop,
                and Mobile apps).
              </StyledDescriptionSubheader>

              <StyledLandingButton dark small onClick={clickRegister}>
                Join now
              </StyledLandingButton>
            </StyledDescriptionContainer>
          </StyledDetailsContainer>
          <StyledDetailsContainer small grey>
            <StyledCenteredContainer>
              <StyledTextNavLink
                to="/privacy"
                target="_blank"
                rel="noreferrer noopener"
                href="#"
              >
                <span>Privacy</span>
              </StyledTextNavLink>
              <StyledTextNavLink
                to="/terms"
                target="_blank"
                rel="noreferrer noopener"
                href="#"
              >
                <span>Terms of use</span>
              </StyledTextNavLink>
              <StyledMailLink
                target="_blank"
                rel="noreferrer noopener"
                href="mailto:Chris@theopenleague.com"
              >
                <span>Contact Us</span>
              </StyledMailLink>
              <StyledExternalLink
                onClick={() => goToUrl('https://twitter.com/playopenleague')}
              >
                <span>Twitter</span>
              </StyledExternalLink>
              <StyledExternalLink
                onClick={() =>
                  goToUrl('https://www.instagram.com/theopenleague/')
                }
              >
                <span>Instagram</span>
              </StyledExternalLink>
              <StyledExternalLink
                onClick={() =>
                  goToUrl('https://www.facebook.com/TheOpenLeague')
                }
              >
                <span>Facebook</span>
              </StyledExternalLink>
              <StyledTextNavLink
                to="/press-kit"
                target="_blank"
                rel="noreferrer noopener"
                href="#"
              >
                <span>Press Kit</span>
              </StyledTextNavLink>
            </StyledCenteredContainer>
          </StyledDetailsContainer>
        </StyledPageWrapper>
      )}
    </>
  );
};

export default StartPage;
